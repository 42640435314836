/** @jsx jsx */
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { jsx, Flex } from "theme-ui";
import { EpisodeConsumer } from "./context";

import { FiMenu as MenuIcon, FiX as CloseMenuIcon } from "react-icons/fi";

import onClickOutside from "react-onclickoutside";
import config from "../lib/config";
import Link from "./link";

import logo from "../images/lenotiziedellailly-logo.png";

function Navigation() {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);
  Navigation.handleClickOutside = () => setIsOpen(false);
  const twoDigits = (n) => (n.toString().length < 2 ? `0${n}` : n);

  const Logo = () => (
    <div className="logo-header">
      <Link
        className="logo-container"
        to="/"
      >
        <img
          alt="Le notizie della illy"
          className="logo-img"
          src={logo}
          style={{ marginBottom: 12, borderRadius: 15 }}
        />
        <div className="logo-text">
          <h1
            sx={{ fontSize: 8, color: "primary", mb: 0 }}
            style={{ fontSize: 27, marginBottom: 10 }}
          >
            {data.site.siteMetadata.title
              ? data.site.siteMetadata.title
              : "Podcast Name"}
          </h1>
          <h4
            sx={{ color: "white", mb: 2 }}
            style={{ fontSize: 20 }}
          >
            La rassegna stampa
            <br />
            per i bambini
          </h4>
          {false && config.podcastSeason && (
            <h5
              className="lenotiziedellailly-stagione"
              sx={{
                textTransform: "uppercase",
                mt: 2,
                mb: 0,
                fontWeight: 400,
                fontSize: 0,
                opacity: 0.6,
              }}
            >
              Stagione {twoDigits(config.podcastSeason)}
            </h5>
          )}
        </div>
      </Link>
    </div>
  );

  const data = useStaticQuery(graphql`
    query navQuery {
      site {
        siteMetadata {
          title
        }
      }
      allEpisode {
        totalCount
        nodes {
          id
          title
          description
          season_number
          number
          fields {
            slug
          }
        }
      }
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              id
              summary
            }
          }
        }
      }
    }
  `);
  return (
    <EpisodeConsumer>
      {(context) => (
        <>
          <Flex
            sx={{
              variant: "header.logo.container",
            }}
          >
            <Flex
              sx={{
                variant: "header.logo",
              }}
            >
              <Logo />
            </Flex>
            <button
              sx={{
                position: "relative",
                zIndex: 998,
                display: "flex",
                p: 3,
                backgroundColor: "background",
                color: "text",
                border: "none",
                fontSize: 25,
              }}
              onClick={toggleMenu}
              aria-controls="menu"
              aria-haspopup="true"
              aria-expanded={isOpen ? "true" : "false"}
            >
              {isOpen ? <CloseMenuIcon /> : <MenuIcon />}
            </button>
          </Flex>
          <nav
            className="episodes_list"
            sx={{
              variant: "navigation.episodes",
              transform: [`translateX(${isOpen ? "0" : "-100%"})`, "none"],
              transition: "300ms cubic-bezier(1, 0, 0, 1)",
            }}
          >
            <div sx={{ ml: 6, pb: 4 }}>
              <Logo />
            </div>
            <ul
              id="menu"
              sx={{ pb: 14 }}
            >
              {data.allEpisode.nodes.slice(0, 20).map((episode, index) => {
                if (index < 10) {
                  return (
                    <li
                      role="none"
                      key={episode.id}
                    >
                      <Link
                        role="menuitem"
                        style={{
                          // borderRadius: 15,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                        activeClassName="active"
                        to={episode.fields.slug}
                      >
                        <h4 style={{ fontSize: 16 }}>{episode.title}</h4>
                        {false && (
                          <p
                            className="notizieilly-new"
                            style={{
                              textDecoration: "none",
                              background: "#F44336",
                              color: "white",
                              padding: "4px 10px",
                              fontWeight: "bold",
                              borderRadius: 10,
                              fontSize: 10,
                              marginBottom: 0,
                              marginTop: 12,
                              width: "fit-content",
                            }}
                          >
                            ULTIMA PUNTATA DELLA STAGIONE
                          </p>
                        )}
                        {(episode.id === "rassegna-stampa-19-dicembre-2020" ||
                          episode.id === "rassegna-stampa-24-dicembre-2021" ||
                          episode.id === "rassegna-stampa-24-dicembre-2022" ||
                          episode.id ===
                            "rassegna-stampa-23-dicembre-2023-christmas-edition" ||
                          episode.id ===
                            "rassegna-stampa-21-dicembre-2024-christmas-edition") && (
                          <p
                            className="notizieilly-new"
                            style={{
                              textDecoration: "none",
                              background: "#F44336",
                              color: "white",
                              padding: "4px 10px",
                              fontWeight: "bold",
                              borderRadius: 10,
                              fontSize: 10,
                              marginBottom: 0,
                              marginTop: 12,
                              width: "fit-content",
                            }}
                          >
                            BUON NATALE!
                          </p>
                        )}
                        {index === 0 && (
                          <span
                            className="notizieilly-new"
                            style={{
                              textDecoration: "none",
                              background: "#ffc107",
                              color: "#1A2232",
                              padding: "4px 10px",
                              fontWeight: "bold",
                              borderRadius: 10,
                              fontSize: 10,
                              marginBottom: 0,
                              marginTop: 12,
                              width: "fit-content",
                            }}
                          >
                            NEW
                          </span>
                        )}
                        {episode.season_number === "1 (ESTATE)" && (
                          <span
                            className="notizieilly-estate"
                            style={{
                              textDecoration: "none",
                              background: "#57b8ea",
                              color: "#1A2232",
                              padding: "4px 10px",
                              fontWeight: "bold",
                              borderRadius: 10,
                              fontSize: 10,
                              marginBottom: 0,
                              marginTop: 12,
                              width: "fit-content",
                            }}
                          >
                            ESTATE!
                          </span>
                        )}
                        <p
                          className="summary"
                          style={{ marginTop: 8, fontSize: 12 }}
                        >
                          {episode.number !== 0 ? (
                            <>
                              Stagione {episode.season_number}
                              {" / "}
                              <strong>Episodio {episode.number}</strong>
                            </>
                          ) : (
                            <>Cos'è?</>
                          )}
                        </p>
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li
                      role="none"
                      key={episode.id}
                    >
                      {episode.id === context.state.id && <Bars />}
                      <Link
                        role="menuitem"
                        style={{
                          borderRadius: 15,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                        activeClassName="active"
                        to={episode.fields.slug}
                      >
                        <h4 style={{ fontSize: 11 }}>{episode.title}</h4>
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
            {false && (
              <span
                className="notizieilly-new"
                style={{
                  textDecoration: "none",
                  background: "#ffc107",
                  color: "#1A2232",
                  padding: "4px 10px",
                  fontWeight: "bold",
                  borderRadius: 10,
                  fontSize: 10,
                  marginBottom: 0,
                  marginTop: 12,
                  width: "fit-content",
                }}
              >
                NEW
              </span>
            )}
          </nav>
        </>
      )}
    </EpisodeConsumer>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Navigation.handleClickOutside,
};

export default onClickOutside(Navigation, clickOutsideConfig);
