import React from "react";
import Layout from "./src/components/layout";

import { EpisodeProvider } from "./src/components/context";
import { ThemeProvider, Box } from "theme-ui";
import theme from "./src/gatsby-plugin-theme-ui/index";
import { SkipNavLink } from "@reach/skip-nav";

export const wrapPageElement = ({ element, props }, options) => {
  return (
    <>
      <EpisodeProvider>
        <Box>
          <SkipNavLink />
          {true && (
            <Layout {...props}>
              <>{element}</>
            </Layout>
          )}
        </Box>
      </EpisodeProvider>
    </>
  );
};
